@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  height: 10%;
}

.background {
  width: 100%;
  flex: 1;
}

h4 {
  color: #311ab5;
  font-family: "Open Sans", "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 123.5%;
  text-align: center;
  letter-spacing: 0.25px;
}

h6 {
  font-family: "Open Sans", "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  text-transform: none;
  letter-spacing: 0.15px;
}

h5 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 133.4%;
  color: white;
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 133.4%;
  color: white;
}

h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: white;
}
